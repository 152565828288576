import { useQuery } from "@tanstack/react-query";
import { attemptGetIndustryNewsInsights } from "../../api/InsightsApi";

const fetchIndustryNewsInsights = async (day, locationID, numberOfArticles) => {
    return await attemptGetIndustryNewsInsights(day, locationID, numberOfArticles);
};

export function useGetIndustryNewsInsights(day, locationID, numberOfArticles) {
    return useQuery({
        queryKey: ["industry-news-insights", day, locationID, numberOfArticles],
        queryFn: () => fetchIndustryNewsInsights(day, locationID, numberOfArticles),
        enabled: !!locationID,
        staleTime: 0,
        cacheTime: 1000 * 60 * 5,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
    });
}