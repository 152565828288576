import * as React from 'react';
import { useProfile, useLocations, useGetFootfallTraffic } from "hooks";
import { Flex, IconButton, Separator } from '@radix-ui/themes';
import { AvatarDropdownMenu } from 'components';
import { ReactComponent as WifiIcon } from '../../assets/icons/favicon-dark.svg';
import { SparklesIcon, ChartBarIcon, BuildingOffice2Icon } from "@heroicons/react/16/solid";
import { Outlet } from 'react-router-dom';
import { findAllByTestId } from '@testing-library/react';

const LocationContext = React.createContext();
const FootfallCardContext = React.createContext();
const TimeContext = React.createContext();

// At the top of InsightsLayout.jsx
const STORAGE_KEY = 'sharedLocation';

// Load initial state from localStorage
const getStoredLocation = () => {
  const stored = localStorage.getItem(STORAGE_KEY);
  return stored ? JSON.parse(stored) : null;
};

export default function InsightsLayout() {

  const { data: profile } = useProfile();

  // Create states for user role and id for it to be accessible outside of the useEffect block
  const [userId, setUserId] = React.useState(null);

  // This side effect sets the userId state with the profile id
  React.useEffect(() => {
    if (profile) {
      setUserId(profile.id);
    }
  }, [profile]);

  const { initialItems } = useLocations(userId);
  
  // Create states for shared location across all <Outlet> components!
  const [sharedLocation, setSharedLocation] = React.useState(getStoredLocation);

  // Update localStorage when location changes
  React.useEffect(() => {
    if (sharedLocation) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(sharedLocation));
    }
  }, [sharedLocation]);

  // Create a state for the current day of the week and hour of the day
  const [time, setTime] = React.useState({
    week_day: null,
    hour: null
  });

  // This side effect gets the time of the day!
  React.useEffect(() => {
    function updateDateTime() {
      const date = new Date();
      const dayOfWeekArray = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
      const dayOfWeekToday = dayOfWeekArray[date.getDay()];
      const hourOfDay = date.getHours();
      
      setTime({
        week_day: dayOfWeekToday,
        hour: hourOfDay
      });
    }
  
    // Run immediately
    updateDateTime();
  
    // Update every hour (3600000 milliseconds)
    const interval = setInterval(updateDateTime, 3600000);
  
    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, []);

  const { data: insights, isLoading }  = useGetFootfallTraffic(time.week_day, sharedLocation?.value);
  const [footfallCards, setFootfallCards] = React.useState({});
  const [filteredFootfallCards, setFilteredFootfallCards] = React.useState([]);
  const [tagArray, setTagArray] = React.useState([]);

  // This side effect updates the footfallCards state with the insights data, and also filters out insights that are 
  // no longer relevant (recall that we want insights to tell us what is going on ahead of the future, not what has
  // already happened)
  React.useEffect(() => {
    if (insights && (!insights?.status || insights.status !== 'Error')) {
      // Clear previous state
      setFootfallCards({});
      setFilteredFootfallCards([]);
      setTagArray([]);

      const data = insights?.chart_data.data.filter((traffic) => traffic > 0);
      const openingInteger = insights?.chart_data.opening_and_closing_time.opens
      const closingInteger = insights?.chart_data.opening_and_closing_time.closes

      if (!data?.length || !openingInteger || !closingInteger) {
        return;
      }

      const labels = Array.from({ length: data.length }, (_, index) => {
        if (index === 0) {
          return openingInteger > 12 ? `${openingInteger - 12}pm` : (openingInteger === 12 ? `${openingInteger}pm` : `${openingInteger}am`);
        } else if (index === data.length - 1) {
          return closingInteger > 12 ? `${closingInteger - 12}pm` : (closingInteger === 12 ? `${closingInteger}pm` : `${closingInteger}am`);
        }
        return ""
      });

      insights.chart_data.data = data
      insights.chart_data.opening_and_closing_time = labels

      // Deletes insights once the time has passed for said insight!
      const hourly_keys = Object.keys(insights?.insights).filter((entry) => entry !== "general")
      
      var initialFilteredFootfallCards = []
      var initialTagArray = []
      for (let entry of hourly_keys) {
        if (entry.includes("hour_")) {
          const hour = parseInt(entry.split("_")[1])
          if (time.hour < hour) {
            initialFilteredFootfallCards.push(entry)
            initialTagArray.push(hour - openingInteger)
          }
        } else if (entry === "before_opening_period") {
          if (time.hour < openingInteger) {
            initialFilteredFootfallCards.push(entry)
            initialTagArray.push("none")
          }

        } else if (entry === "after_closing_period") {
          if (time.hour < closingInteger) {
            initialFilteredFootfallCards.push(entry)
            initialTagArray.push("all")
          }
        }
      }

      setFootfallCards(insights);
      setFilteredFootfallCards(initialFilteredFootfallCards);
      setTagArray(initialTagArray);
    }
  }, [insights, time, sharedLocation]);

  return (
    <LocationContext.Provider value={{ initialItems, sharedLocation, setSharedLocation }}>
      <TimeContext.Provider value={{ time, filteredFootfallCards, tagArray }}>
        <FootfallCardContext.Provider value={{ footfallCards, isLoading }}>
          <div style={{backgroundColor: "var(--color-background)", height: "100%", width: "100%"}} >
            <Flex className="frame-528172816" style={{alignSelf: "stretch"}} display="flex" height="100%">
              <Flex className="sidebar-container" style={{background: "var(--color-panel-solid)", position: "fixed", height: "100%"}} display="flex" px="2" py="5" direction="column" justify="between" align="center">
                <IconButton size="3" variant="ghost" style={{cursor: 'pointer'}}>
                  <WifiIcon width="24" height="24" />
                </IconButton>
                <Flex className="frame-528172819" display="flex" direction="column" align="center" gap="2">
                  <IconButton size="3" variant="solid" style={{cursor: 'pointer'}}>
                    <SparklesIcon width="24" height="24" />
                  </IconButton>
                  <Flex className="frame-528172817" width="40px" height="40px" display="flex" align="center" justify="center">
                    <IconButton size="3" variant="ghost" style={{cursor: 'pointer'}}>
                      <ChartBarIcon color="gray" width="24" height="24" />
                    </IconButton>
                  </Flex>
                </Flex>
                <Flex className="frame-528172820" display="flex" direction="column" align="center" gap="4">
                  <Flex className="frame-528172817" width="40px" height="40px" display="flex" align="center" justify="center">
                    <IconButton size="3" variant="ghost" style={{cursor: 'pointer'}}>
                      <BuildingOffice2Icon color="gray" width="24" height="24" />
                    </IconButton>
                  </Flex>
                  <AvatarDropdownMenu size="3"/>
                </Flex>
              </Flex>
              <Separator orientation="vertical" size="4"/>
              <Outlet /> 
              {/* This is where the routed components will be rendered */}
            </Flex>
          </div>
        </FootfallCardContext.Provider>
      </TimeContext.Provider>
    </LocationContext.Provider>
  );
}

export const useLocationContext = () => React.useContext(LocationContext);
export const useFootfallCardContext = () => React.useContext(FootfallCardContext);
export const useTimeContext = () => React.useContext(TimeContext);