import { useQuery } from '@tanstack/react-query';
import { attemptGetFootfallTraffic } from '../../api/InsightsApi';

const ONE_DAY = 1000 * 60 * 60 * 24; // 24 hours in milliseconds

const fetchFootfallTraffic = async (day, locationID) => {;
  return await attemptGetFootfallTraffic(day, locationID).then((res) => res.data);
};

export const useGetFootfallTraffic = (day, locationID) => {
  return useQuery({ 
    queryKey: ['insights-footfall-traffic', day, locationID], 
    queryFn: () => fetchFootfallTraffic(day, locationID),
    enabled: !!locationID,
    staleTime: ONE_DAY, // Mark data as stale immediately to ensure a fresh fetch
    cacheTime: ONE_DAY, // Cache data for 5 minutes (adjust as needed)
    refetchOnWindowFocus: true, // Refetch when window regains focus
    refetchOnReconnect: true, // Refetch if network connection is lost and re-establishe
   });
};