import * as React from 'react';
import { Flex, Text, Button } from '@radix-ui/themes';
import { ExternalInsightsCard, LocationSelect } from 'components';
import { ReactComponent as GradientSparklesIcon } from 'assets/icons/gradient_sparkles_purple.svg';
import { useLocationContext } from "./InsightsLayout";

export default function AllFootfallInsights() {

  const { initialItems, sharedLocation, setSharedLocation } = useLocationContext();

  return (
    <Flex className="content-container" display="flex" direction="column" align="start" flexGrow="1">
      <Flex className="frame-528172569" style={{alignSelf: "stretch"}} display="flex" px="7" py="8" direction="column" align="center" gap="7" flexGrow="1">
        <Flex className="frame-528173200" display="flex" py="5" direction="column" justify="center" align="center" gap="5">
          <Flex className="header-title" style={{alignSelf: "stretch"}} display="flex" align="center" gap="3" >
            <GradientSparklesIcon />
            <Text style={{color: "var(--purple-12)"}} size="8" weight="bold">External impact</Text>
          </Flex>
        </Flex>
        <Flex className="header-container" display="flex" align="center" gap="5" >
          <Flex className="header-buttons" display="flex" align="start" gap="2">
            <Button size="3" variant="soft" color="accent" style={{cursor: 'pointer'}}>Today</Button>
            <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>Tomorrow</Button>
            <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>W</Button>
            <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>M</Button>
            <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>Y</Button>
          </Flex>
          <LocationSelect
            initialItems={initialItems}
            sharedLocation={sharedLocation}
            setSharedLocation={setSharedLocation}
          />
        </Flex>
        <Flex className="card-container" style={{alignSelf: "center"}} display="flex" maxWidth="1000px" direction="column" gap="5">
          <Flex className="two-column-alignment" style={{alignSelf: "stretch"}} display="flex" maxWidth="1000px" align="start" gap="5">
            <Flex className="internal-column-alignment" style={{alignSelf: "stretch"}} display="flex" maxWidth="1000px" direction="column" align="start" gap="5" flexGrow="1">
              <ExternalInsightsCard 
                title="A local street market is increasing foot traffic in the area."
                subtitle="Consider promotions for attendees of Shoreditch Flower Market or set up something outside that could encourage walk-ins."
                time="8am"
                link="https://www.google.com"
              />
              <ExternalInsightsCard 
                title="A local street market is increasing foot traffic in the area."
                subtitle="Consider promotions for attendees of Shoreditch Flower Market or set up something outside that could encourage walk-ins."
                time="8am"
                link="https://www.google.com"
              />
              <ExternalInsightsCard 
                title="A local street market is increasing foot traffic in the area."
                subtitle="Consider promotions for attendees of Shoreditch Flower Market or set up something outside that could encourage walk-ins."
                time="8am"
                link="https://www.google.com"
              />

              {/* add cards belonging in the first column here! */}
            </Flex>
            <Flex className="internal-column-alignment" style={{alignSelf: "stretch"}} display="flex" maxWidth="1000px" direction="column" align="start" gap="5" flexGrow="1">
              <ExternalInsightsCard 
                title="A local street market is increasing foot traffic in the area."
                subtitle="Consider promotions for attendees of Shoreditch Flower Market or set up something outside that could encourage walk-ins."
                time="8am"
                link="https://www.google.com"
              />
              <ExternalInsightsCard 
                title="A local street market is increasing foot traffic in the area."
                subtitle="Consider promotions for attendees of Shoreditch Flower Market or set up something outside that could encourage walk-ins."
                time="8am"
                link="https://www.google.com"
              />
              <ExternalInsightsCard 
                title="A local street market is increasing foot traffic in the area."
                subtitle="Consider promotions for attendees of Shoreditch Flower Market or set up something outside that could encourage walk-ins."
                time="8am"
                link="https://www.google.com"
              />

              {/* add cards belonging in the second column here! */}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}