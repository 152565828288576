import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Text } from '@radix-ui/themes';
import { FootfallChart } from '../../components/charts/FootfallChart';
import { ArrowTrendingUpIcon } from "@heroicons/react/16/solid";

export function FootfallCard({
  cardWidth,
  chartHeight,
  icon = true,
  showTitle = true,
  text,
  data,
  tag = null
}) {
  const navigate = useNavigate();

  if (!text || (Array.isArray(data) && data.length === 0)) {
    return null 
  }

  return (
    <div
      className="footfall-card"
      onClick={() => navigate("/insights/footfall")}
      style={{
        display: "flex", 
        width: cardWidth,
        padding: "var(--space-4)", 
        flexDirection: "column", 
        alignItems: "flex-start", 
        gap: "var(--space-4)", 
        alignSelf: "stretch",
        borderRadius: "var(--radius-4)",
        background: "linear-gradient(111deg, var(--orange-4) 22.52%, var(--orange-3) 77.48%)",
        cursor: "pointer"
      }}>
      {showTitle && <Text size="2" weight="medium" color="orange">Footfall</Text>}
      <Flex className="card-header" style={{alignSelf: "stretch"}} display="flex" direction="column" align="start" gap="2">
        {icon ? 
          <Flex className="card-title" style={{alignSelf: "stretch"}} display="flex" align="center" gap="2">
            <ArrowTrendingUpIcon width="20" height="20" color="var(--orange-12)"/>
            <Text style={{color: "var(--orange-12)"}} size="5" weight="bold">{text.forecast_insight}</Text>
          </Flex> : 
          <Text style={{color: "var(--orange-12)"}} size="5" weight="bold">{text.forecast_insight}</Text>}
        <Text size="2" weight="regular" color="orange">{text.suggested_action}</Text>
      </Flex>
      <FootfallChart chartHeight={chartHeight} labels={data.opening_and_closing_time} sample={data.data} tag={tag}/>
    </div>
  );
}