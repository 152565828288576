import * as React from "react";
import { useNavigate } from 'react-router-dom';
import { Flex, Text, Button } from '@radix-ui/themes';
import { ExternalInsightsCard, PotentialActionsCard, LocationSelect } from 'components';
import { useLocationContext } from "./InsightsLayout";

export default function ExternalImpactInsights() {

  const navigate = useNavigate();
  const { initialItems, sharedLocation, setSharedLocation } = useLocationContext();

  return (
    <Flex className="content-container" display="flex" direction="column" align="center" flexGrow="1">
      <Flex className="frame-528172569" display="flex" px="7" py="8" direction="column" align="center" gap="7" >
        <Flex className="header" style={{alignSelf: "stretch"}} display="flex" maxWidth="1000px" justify="between" align="center">
          <Text style={{color: "var(--purple-12)"}} size="6" weight="bold">External impact</Text>
          <Flex className="frame-528173237" display="flex" align="center" gap="2">
            <Flex className="header-buttons" display="flex" align="start" gap="2">
              <Button size="3" variant="soft" color="accent" style={{cursor: 'pointer'}}>Today</Button>
              <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>Tomorrow</Button>
              <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>W</Button>
              <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>M</Button>
              <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>Y</Button>
            </Flex>
            <LocationSelect
              initialItems={initialItems}
              sharedLocation={sharedLocation}
              setSharedLocation={setSharedLocation}
            />
          </Flex>
        </Flex>
        <ExternalInsightsCard
          title="Traffic is forecasted to slow down after 10 AM as the morning rush ends."
          subtitle="Use this time to replenish stock and prepare for the lunchtime crowd."
          time="8am"
          link="https://www.google.com"
          variant="expanded"
        />
        <Flex className="potential-actions-section" style={{alignSelf: "stretch"}} display="flex" maxWidth="1000px" direction="column" justify="center" align="center" gap="5" >
          <Flex className="potential-actions-header" style={{alignSelf: "stretch"}} display="flex" justify="between" align="end">
            <Text style={{color: "var(--purple-12)"}} size="4" weight="bold">Potential Actions</Text>
            <Button size="2" variant="soft" color="gray" style={{cursor: 'pointer'}}>More</Button>
          </Flex>
          <Flex className="potential-actions-body" style={{alignSelf: "stretch"}} display="flex" align="start" gap="5">
            <PotentialActionsCard
              title="Review Table Turnover"
              subtitle="Ensure tables are cleared and ready for the anticipated rush."
              backgroundColor='var(--purple-2)'
              titleColor='var(--purple-12)'
              subtitleColor='var(--purple-11)'
            />
            <PotentialActionsCard
              title="Check Inventory Levels"
              subtitle="Verify stock for popular items typically ordered during lunch rushes."
              backgroundColor='var(--purple-2)'
              titleColor='var(--purple-12)'
              subtitleColor='var(--purple-11)'
            />
            <PotentialActionsCard
              title="Adjust Music or Ambiance"
              subtitle="Slightly elevate the energy of the ambiance (e.g., background music) to match the increased crowd."
              backgroundColor='var(--purple-2)'
              titleColor='var(--purple-12)'
              subtitleColor='var(--purple-11)'
            />
          </Flex>
        </Flex>
        <Flex className="more-footfall-traffic-section" style={{alignSelf: "stretch"}} display="flex" maxWidth="1000px" direction="column" justify="center" align="center" gap="5">
          <Flex className="more-footfall-traffic-header" style={{alignSelf: "stretch"}} display="flex" justify="between" align="end">
            <Text style={{color: "var(--purple-12)"}} size="6" weight="bold">More external impact</Text>
            <Button style={{cursor: "pointer"}} onClick={() => navigate("show-all")} size="2" variant="soft" color="gray">Show All</Button>
          </Flex>
          <Flex className="more-footfall-traffic-body" style={{alignSelf: "stretch"}} display="flex" align="start" gap="5">
            <ExternalInsightsCard
              title="Traffic is forecasted to slow down after 10 AM as the morning rush ends."
              subtitle="Use this time to replenish stock and prepare for the lunchtime crowd."
              time="8am"
              link="https://www.google.com"
            />
            <ExternalInsightsCard
              title="Traffic is forecasted to slow down after 10 AM as the morning rush ends."
              subtitle="Use this time to replenish stock and prepare for the lunchtime crowd."
              time="8am"
              link="https://www.google.com"
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}