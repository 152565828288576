import * as React from "react";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Flex, Text, Button } from '@radix-ui/themes';
import { LocationSelect, InsightsCard, PotentialActionsCard } from 'components';
import { useLocationContext } from "../InsightsLayout";
import { useGetIndustryNewsInsights } from 'hooks';

export default function IndustryNewsInsights() {

  const navigate = useNavigate();
  const { initialItems, sharedLocation, setSharedLocation } = useLocationContext();

  const [timePara, setTimePara] = useState('today');
  const numberOfArticles = 3;
  const { data: industryNewsInsights } = useGetIndustryNewsInsights(timePara, sharedLocation?.value, numberOfArticles);

  return (
    <Flex className="content-container" display="flex" direction="column" align="center" flexGrow="1">
      <Flex display="flex" px="7" py="8" direction="column" align="center" gap="7" >
        <Flex className="header" style={{alignSelf: "stretch"}} display="flex" maxWidth="1000px" justify="between" align="center">
          <Text style={{color: "var(--violet-12)"}} size="6" weight="bold">Industry News</Text>
          <Flex display="flex" align="center" gap="2">
            <Flex className="header-buttons" display="flex" align="start" gap="2">
              <Button size="3" variant="soft" color="accent" style={{cursor: 'pointer'}}>Today</Button>
              <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>Yesterday</Button>
              <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>W</Button>
              <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>M</Button>
              <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>Y</Button>
            </Flex>
            <LocationSelect
              initialItems={initialItems}
              sharedLocation={sharedLocation}
              setSharedLocation={setSharedLocation}
            />
          </Flex>
        </Flex>
        {/* Main Header Card */}
        <InsightsCard
          cardWidth="1000px"
          cardHeight="400px"
          heading={industryNewsInsights?.data.articles[0].insight}
          headingColor='var(--violet-12)'
          content={industryNewsInsights?.data.articles[0].action}
          contentColor='var(--violet-11)'
          backgroundColor1='var(--violet-4)'
          backgroundColor2='var(--violet-3)'
          image_url={industryNewsInsights?.data.articles[0].image}
          source={industryNewsInsights?.data.articles[0].source}
          source_url={industryNewsInsights?.data.articles[0].url}
          variant="expanded"
        />
        <Flex className="potential-actions-section" style={{alignSelf: "stretch"}} display="flex" maxWidth="1000px" direction="column" justify="center" align="center" gap="5" >
          <Flex className="potential-actions-header" style={{alignSelf: "stretch"}} display="flex" justify="between" align="end">
            <Text style={{color: "var(--violet-12)"}} size="4" weight="bold">Potential Actions</Text>
            <Button size="2" variant="soft" color="gray" style={{cursor: 'pointer'}}>More</Button>
          </Flex>
          <Flex className="potential-actions-body" style={{alignSelf: "stretch"}} display="flex" align="start" gap="5">
            <PotentialActionsCard
              title={industryNewsInsights?.data.articles[0].actionable_insights[0].potential_action}
              subtitle={industryNewsInsights?.data.articles[0].actionable_insights[0].actionable_insight}
              backgroundColor="var(--violet-2)"
              titleColor="var(--violet-12)"
              subtitleColor="var(--violet-11)"
            />
            <PotentialActionsCard
              title={industryNewsInsights?.data.articles[0].actionable_insights[1].potential_action}
              subtitle={industryNewsInsights?.data.articles[0].actionable_insights[1].actionable_insight}
              backgroundColor="var(--violet-2)"
              titleColor="var(--violet-12)"
              subtitleColor="var(--violet-11)"
            />
            <PotentialActionsCard
              title={industryNewsInsights?.data.articles[0].actionable_insights[2].potential_action}
              subtitle={industryNewsInsights?.data.articles[0].actionable_insights[2].actionable_insight}
              backgroundColor="var(--violet-2)"
              titleColor="var(--violet-12)"
              subtitleColor="var(--violet-11)"
            />
          </Flex>
        </Flex>
        <Flex className="more-industry-news-section" style={{alignSelf: "stretch"}} display="flex" maxWidth="1000px" direction="column" justify="center" align="center" gap="5">
          <Flex className="more-industry-news-header" style={{alignSelf: "stretch"}} display="flex" justify="between" align="end">
            <Text style={{color: "var(--violet-12)"}} size="6" weight="bold">More industry news</Text>
            <Button style={{cursor: "pointer"}} onClick={() => navigate("show-all")} size="2" variant="soft" color="gray">Show All</Button>
          </Flex>
          <Flex className="more-industry-news-body" style={{alignSelf: "stretch"}} display="flex" align="start" gap="5">
            {/* Industry News Insigts Card */}
            <InsightsCard
              cardWidth='490px'
              cardHeight='352px'
              cardTitle="Industry News"
              heading={industryNewsInsights?.data.articles[1].insight}
              headingColor='var(--violet-12)'
              content={industryNewsInsights?.data.articles[1].action}
              contentColor='var(--violet-11)'
              backgroundColor1='var(--violet-4)'
              backgroundColor2='var(--violet-3)'
              nav_url="/insights/industry-news"
              image_url={industryNewsInsights?.data.articles[1].image}
              source={industryNewsInsights?.data.articles[1].source}
              source_url={industryNewsInsights?.data.articles[1].url}
              variant="concise"
              redirect={false}
            />
            {/* Industry News Insigts Card */}
            <InsightsCard
              cardWidth='490px'
              cardHeight='352px'
              cardTitle="Industry News"
              heading={industryNewsInsights?.data.articles[2].insight}
              headingColor='var(--violet-12)'
              content={industryNewsInsights?.data.articles[2].action}
              contentColor='var(--violet-11)'
              backgroundColor1='var(--violet-4)'
              backgroundColor2='var(--violet-3)'
              nav_url="/insights/industry-news"
              image_url={industryNewsInsights?.data.articles[2].image}
              source={industryNewsInsights?.data.articles[2].source}
              source_url={industryNewsInsights?.data.articles[2].url}
              variant="concise"
              redirect={false}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}