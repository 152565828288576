import * as React from 'react';
import { useState } from 'react';
import { Flex, Text, Button } from '@radix-ui/themes';
import { FootfallCard, ExternalInsightsCard, LocationSelect, InsightsCard } from 'components';
import { SparklesIcon } from "@heroicons/react/16/solid";
import { useLocationContext, useFootfallCardContext } from "./InsightsLayout";
import { useGetIndustryNewsInsights } from 'hooks';

export default function Insights() {

  const { initialItems, sharedLocation, setSharedLocation } = useLocationContext();
  const { footfallCards, isLoading } = useFootfallCardContext();

  const [timePara, setTimePara] = useState('today');
  const numberOfArticles = 1;
  const { data: industryNewsInsights } = useGetIndustryNewsInsights(timePara, sharedLocation?.value, numberOfArticles);

  return (
    <Flex className="content-container" display="flex" direction="column" align="start" flexGrow="1">
      <Flex className="frame-528172569" style={{alignSelf: "stretch"}} display="flex" px="7" py="8" direction="column" align="center" gap="7" flexGrow="1">
        <Flex className="frame-528173200" display="flex" py="5" direction="column" justify="center" align="center" gap="5">
          <Flex className="header-title" style={{alignSelf: "stretch"}} display="flex" align="center" gap="3" >
            <SparklesIcon width="24" height="24" />
            <Text size="8" weight="bold">Insights</Text>
          </Flex>
        </Flex>
        <Flex className="header-container" display="flex" align="center" gap="5" >
          <Flex className="header-buttons" display="flex" align="start" gap="2">
            <Button size="3" variant="soft" color="accent" style={{cursor: 'pointer'}}>Today</Button>
            <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>Tomorrow</Button>
            <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>W</Button>
            <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>M</Button>
            <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>Y</Button>
          </Flex>
          <LocationSelect
            initialItems={initialItems}
            sharedLocation={sharedLocation}
            setSharedLocation={setSharedLocation}
          />
        </Flex>
        <Flex className="card-container" style={{alignSelf: "center"}} display="flex" maxWidth="1000px" direction="column" gap="5">
          <Flex className="two-column-alignment" style={{alignSelf: "stretch"}} display="flex" maxWidth="1000px" align="start" gap="5">
            <Flex className="internal-column-alignment" style={{alignSelf: "stretch"}} display="flex" maxWidth="1000px" direction="column" align="start" gap="5" flexGrow="1">
              <FootfallCard 
                cardWidth='490px'
                chartHeight='108px'
                text={footfallCards?.insights?.general[0].insight_one}
                data={footfallCards?.chart_data}
              />
              {/* Industry News Insigts Card */}
              <InsightsCard
                cardWidth='490px'
                cardHeight='352px'
                cardTitle="Industry News"
                heading={industryNewsInsights?.data.articles[0].insight}
                headingColor='var(--violet-12)'
                content={industryNewsInsights?.data.articles[0].action}
                contentColor='var(--violet-11)'
                backgroundColor1='var(--violet-4)'
                backgroundColor2='var(--violet-3)'
                nav_url="/insights/industry-news"
                image_url={industryNewsInsights?.data.articles[0].image}
                source={industryNewsInsights?.data.articles[0].source}
                source_url={industryNewsInsights?.data.articles[0].url}
                variant="concise"
                redirect={true}
              />
            </Flex>
            <Flex className="internal-column-alignment" style={{alignSelf: "stretch"}} display="flex" maxWidth="1000px" direction="column" align="start" gap="5" flexGrow="1">
              <ExternalInsightsCard 
                title="A local street market is increasing foot traffic in the area."
                subtitle="Consider promotions for attendees of Shoreditch Flower Market or set up something outside that could encourage walk-ins."
                time="8am"
                link="https://www.google.com"
              />
              <FootfallCard 
                cardWidth='490px'
                chartHeight='108px'
                text={footfallCards?.insights?.general[0].insight_two}
                data={footfallCards?.chart_data}
              />
              {/* add cards belonging in the second column here! */}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}