import axios from "axios";
import params from "../configs/params";
// import { attemptGetAccessTokenByRefreshToken } from "./AuthApi";

const insightsApi = axios.create({
  baseURL: params.insightsUrl,
  withCredentials: true,
});

insightsApi.defaults.headers.common["Content-Type"] = "application/json";
insightsApi.defaults.headers.common["ngrok-skip-browser-warning"] = "true";

insightsApi.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (accessToken && refreshToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default insightsApi