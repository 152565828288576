import * as React from 'react';
import { useState } from 'react';
import { Flex, Text, Button } from '@radix-ui/themes';
import { LocationSelect, InsightsCard } from 'components';
import { ReactComponent as GradientSparklesIcon } from 'assets/icons/gradient_sparkles_purple.svg';
import { useLocationContext } from "../InsightsLayout";
import { useGetIndustryNewsInsights } from 'hooks';

export default function AllIndustryNewsInsights() {

  const { initialItems, sharedLocation, setSharedLocation } = useLocationContext();

  const [timePara, setTimePara] = useState('today');
  const numberOfArticles = 10;
  const { data: industryNewsInsights } = useGetIndustryNewsInsights(timePara, sharedLocation?.value, numberOfArticles);

  return (
    <Flex className="content-container" display="flex" direction="column" align="start" flexGrow="1">
      <Flex style={{alignSelf: "stretch"}} display="flex" px="7" py="8" direction="column" align="center" gap="7" flexGrow="1">
        <Flex display="flex" py="5" direction="column" justify="center" align="center" gap="5">
          <Flex className="header-title" style={{alignSelf: "stretch"}} display="flex" align="center" gap="3" >
            <GradientSparklesIcon />
            <Text style={{color: "var(--violet-12)"}} size="8" weight="bold">Industry News</Text>
          </Flex>
        </Flex>
        <Flex className="header-container" display="flex" align="center" gap="5" >
          <Flex className="header-buttons" display="flex" align="start" gap="2">
            <Button size="3" variant="soft" color="accent" style={{cursor: 'pointer'}}>Today</Button>
            <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>Yesterday</Button>
            <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>W</Button>
            <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>M</Button>
            <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>Y</Button>
          </Flex>
          <LocationSelect
            initialItems={initialItems}
            sharedLocation={sharedLocation}
            setSharedLocation={setSharedLocation}
          />
        </Flex>
        <Flex className="card-container" style={{alignSelf: "center"}} display="flex" maxWidth="1000px" direction="column" gap="5">
          <Flex className="two-column-alignment" style={{alignSelf: "stretch"}} display="flex" maxWidth="1000px" align="start" gap="5">
            <Flex className="internal-column-alignment" style={{alignSelf: "stretch"}} display="flex" maxWidth="1000px" direction="column" align="start" gap="5" flexGrow="1">
              {/* Industry News Insigts Cards Column 1 */}
              {industryNewsInsights?.data.articles[0] &&
                <InsightsCard
                  cardWidth='490px'
                  cardHeight='352px'
                  cardTitle="Industry News"
                  heading={industryNewsInsights?.data.articles[0].insight}
                  headingColor='var(--violet-12)'
                  content={industryNewsInsights?.data.articles[0].action}
                  contentColor='var(--violet-11)'
                  backgroundColor1='var(--violet-4)'
                  backgroundColor2='var(--violet-3)'
                  image_url={industryNewsInsights?.data.articles[0].image}
                  source={industryNewsInsights?.data.articles[0].source}
                  source_url={industryNewsInsights?.data.articles[0].url}
                  variant="concise"
                  redirect={false}
                />
              }
              {industryNewsInsights?.data.articles[2] &&
                <InsightsCard
                  cardWidth='490px'
                  cardHeight='352px'
                  cardTitle="Industry News"
                  heading={industryNewsInsights?.data.articles[2].insight}
                  headingColor='var(--violet-12)'
                  content={industryNewsInsights?.data.articles[2].action}
                  contentColor='var(--violet-11)'
                  backgroundColor1='var(--violet-4)'
                  backgroundColor2='var(--violet-3)'
                  image_url={industryNewsInsights?.data.articles[2].image}
                  source={industryNewsInsights?.data.articles[2].source}
                  source_url={industryNewsInsights?.data.articles[2].url}
                  variant="concise"
                  redirect={false}
                />
              }
              {industryNewsInsights?.data.articles[4] &&
                <InsightsCard
                  cardWidth='490px'
                  cardHeight='352px'
                  cardTitle="Industry News"
                  heading={industryNewsInsights?.data.articles[4].insight}
                  headingColor='var(--violet-12)'
                  content={industryNewsInsights?.data.articles[4].action}
                  contentColor='var(--violet-11)'
                  backgroundColor1='var(--violet-4)'
                  backgroundColor2='var(--violet-3)'
                  image_url={industryNewsInsights?.data.articles[4].image}
                  source={industryNewsInsights?.data.articles[4].source}
                  source_url={industryNewsInsights?.data.articles[4].url}
                  variant="concise"
                  redirect={false}
                />
              }
              {industryNewsInsights?.data.articles[6] &&
                <InsightsCard
                  cardWidth='490px'
                  cardHeight='352px'
                  cardTitle="Industry News"
                  heading={industryNewsInsights?.data.articles[6].insight}
                  headingColor='var(--violet-12)'
                  content={industryNewsInsights?.data.articles[6].action}
                  contentColor='var(--violet-11)'
                  backgroundColor1='var(--violet-4)'
                  backgroundColor2='var(--violet-3)'
                  image_url={industryNewsInsights?.data.articles[6].image}
                  source={industryNewsInsights?.data.articles[6].source}
                  source_url={industryNewsInsights?.data.articles[6].url}
                  variant="concise"
                  redirect={false}
                />
              }
              {industryNewsInsights?.data.articles[8] &&
                <InsightsCard
                  cardWidth='490px'
                  cardHeight='352px'
                  cardTitle="Industry News"
                  heading={industryNewsInsights?.data.articles[8].insight}
                  headingColor='var(--violet-12)'
                  content={industryNewsInsights?.data.articles[8].action}
                  contentColor='var(--violet-11)'
                  backgroundColor1='var(--violet-4)'
                  backgroundColor2='var(--violet-3)'
                  image_url={industryNewsInsights?.data.articles[8].image}
                  source={industryNewsInsights?.data.articles[8].source}
                  source_url={industryNewsInsights?.data.articles[8].url}
                  variant="concise"
                  redirect={false}
                />
              }
              {/* add cards belonging in the first column here! */}
            </Flex>
            <Flex className="internal-column-alignment" style={{alignSelf: "stretch"}} display="flex" maxWidth="1000px" direction="column" align="start" gap="5" flexGrow="1">
              {/* Industry News Insigts Cards Column 2 */}
              {industryNewsInsights?.data.articles[1] &&
                <InsightsCard
                  cardWidth='490px'
                  cardHeight='352px'
                  cardTitle="Industry News"
                  heading={industryNewsInsights?.data.articles[1].insight}
                  headingColor='var(--violet-12)'
                  content={industryNewsInsights?.data.articles[1].action}
                  contentColor='var(--violet-11)'
                  backgroundColor1='var(--violet-4)'
                  backgroundColor2='var(--violet-3)'
                  image_url={industryNewsInsights?.data.articles[1].image}
                  source={industryNewsInsights?.data.articles[1].source}
                  source_url={industryNewsInsights?.data.articles[1].url}
                  variant="concise"
                  redirect={false}
                />
              }
              {industryNewsInsights?.data.articles[3] &&
                <InsightsCard
                  cardWidth='490px'
                  cardHeight='352px'
                  cardTitle="Industry News"
                  heading={industryNewsInsights?.data.articles[3].insight}
                  headingColor='var(--violet-12)'
                  content={industryNewsInsights?.data.articles[3].action}
                  contentColor='var(--violet-11)'
                  backgroundColor1='var(--violet-4)'
                  backgroundColor2='var(--violet-3)'
                  image_url={industryNewsInsights?.data.articles[3].image}
                  source={industryNewsInsights?.data.articles[3].source}
                  source_url={industryNewsInsights?.data.articles[3].url}
                  variant="concise"
                  redirect={false}
                />
              }
              {industryNewsInsights?.data.articles[5] &&
                <InsightsCard
                  cardWidth='490px'
                  cardHeight='352px'
                  cardTitle="Industry News"
                  heading={industryNewsInsights?.data.articles[5].insight}
                  headingColor='var(--violet-12)'
                  content={industryNewsInsights?.data.articles[5].action}
                  contentColor='var(--violet-11)'
                  backgroundColor1='var(--violet-4)'
                  backgroundColor2='var(--violet-3)'
                  image_url={industryNewsInsights?.data.articles[5].image}
                  source={industryNewsInsights?.data.articles[5].source}
                  source_url={industryNewsInsights?.data.articles[5].url}
                  variant="concise"
                  redirect={false}
                />
              }
              {industryNewsInsights?.data.articles[7] &&
                <InsightsCard
                  cardWidth='490px'
                  cardHeight='352px'
                  cardTitle="Industry News"
                  heading={industryNewsInsights?.data.articles[7].insight}
                  headingColor='var(--violet-12)'
                  content={industryNewsInsights?.data.articles[7].action}
                  contentColor='var(--violet-11)'
                  backgroundColor1='var(--violet-4)'
                  backgroundColor2='var(--violet-3)'
                  image_url={industryNewsInsights?.data.articles[7].image}
                  source={industryNewsInsights?.data.articles[7].source}
                  source_url={industryNewsInsights?.data.articles[7].url}
                  variant="concise"
                  redirect={false}
                />
              }
              {industryNewsInsights?.data.articles[9] &&
                <InsightsCard
                  cardWidth='490px'
                  cardHeight='352px'
                  cardTitle="Industry News"
                  heading={industryNewsInsights?.data.articles[9].insight}
                  headingColor='var(--violet-12)'
                  content={industryNewsInsights?.data.articles[9].action}
                  contentColor='var(--violet-11)'
                  backgroundColor1='var(--violet-4)'
                  backgroundColor2='var(--violet-3)'
                  image_url={industryNewsInsights?.data.articles[9].image}
                  source={industryNewsInsights?.data.articles[9].source}
                  source_url={industryNewsInsights?.data.articles[9].url}
                  variant="concise"
                  redirect={false}
                />
              }
              {/* add cards belonging in the second column here! */}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}