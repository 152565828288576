import * as React from 'react';
import { Flex, Select } from '@radix-ui/themes';
import { BuildingStorefrontIcon } from "@heroicons/react/16/solid";

export function LocationSelect({initialItems, sharedLocation, setSharedLocation}) {

  // Ensure that the value of `sharedLocation` is set to an initial value if undefined or null
  React.useEffect(() => {
    if (sharedLocation == null && initialItems?.length > 0) {
      // Set the initial shared location to the first item or any default logic you prefer
      setSharedLocation(initialItems[0]);
    }
  }, [sharedLocation, initialItems, setSharedLocation]);

  const handleSharedLocation = (event) => {
    setSharedLocation(initialItems?.find((item) => item.value === event));
  }

  return (
    <Select.Root value={sharedLocation?.value} onValueChange={handleSharedLocation} >
      <Select.Trigger size="3" variant="soft" style={{cursor: 'pointer'}}>
        <Flex as="span" align="center" gap="2">
          <BuildingStorefrontIcon height="18" width="18"/>
          {sharedLocation?.label}
        </Flex>
      </Select.Trigger>
      <Select.Content>
        <Select.Group>
          {
            initialItems?.map((item) => (
              <Select.Item value={item.value} key={item.value}>{item.label}</Select.Item>
            ))
          }
        </Select.Group>
      </Select.Content>
    </Select.Root>  
  )
}