import * as React from 'react';
import { Flex, Text, Badge, Link } from '@radix-ui/themes';
import { useNavigate } from 'react-router-dom';

export function InsightsCard ({cardWidth, cardHeight, cardTitle, heading, content, image_url, source, source_url,
    backgroundColor1, backgroundColor2, headingColor, contentColor, nav_url, variant, redirect,
}) {
    const navigate = useNavigate();

    if (variant === "concise") {
        return (
            <div className="insights-card"
                style={{width: `${cardWidth}`, height: `${cardHeight}`}}>
                <Flex direction="column" gap="4" p="4" justify='between'
                    style={{alignItems: 'flex-start', alignSelf: 'stretch', borderRadius: 'var(--radius-4)', height: '100%',
                    background: `linear-gradient(111deg, ${backgroundColor1} 22.52%, ${backgroundColor2} 77.48%)`,
                    }}>
                    {/* Title and Time */}
                    <Flex style={{alignItems: 'center', alignSelf: 'stretch'}}>
                        <Text size="2" weight="medium" style={{color: `${contentColor}`}}>{cardTitle}</Text>
                    </Flex>
                    {/* Content */}
                    <Flex direction="column" align="start" gap="2" 
                        style={{alignSelf: 'stretch', cursor: redirect ? 'pointer' : 'default'}}
                        onClick={() => navigate(nav_url)}>
                        <Text size="5" weight="bold" style={{color: `${headingColor}`}}>{heading}</Text>
                        <Text size="2" weight="regular" style={{color: `${contentColor}`}}>{content}</Text>
                    </Flex>
                    {/* Image/Chart */}
                    <Flex onClick={() => navigate(nav_url)} style={{height: '108px', alignSelf: 'stretch', borderRadius: 'var(--radius-2)', 
                        cursor: redirect ? 'pointer' : 'default',
                        backgroundImage: `url(${image_url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        mixBlendMode: 'luminosity',
                    }}>
                    </Flex>
                    {/* Source */}
                    <Flex style={{alignSelf: 'stretch', justifyContent: 'flex-end'}}>
                        <Text size="2" weight="regular" style={{color: `${contentColor}`}}>
                            <Link href={source_url} target='_blank' rel='noopener noreferrer'>
                                <Badge size="1" variant='soft' color='violet'>{source}</Badge>
                            </Link>
                        </Text>
                    </Flex>
                </Flex>
            </div>
        );
    }
    else if (variant === "expanded") {
        return (
            <div className="insights-card-expanded" style={{width: '100%', maxWidth: `${cardWidth}`,
                height: `${cardHeight}`}}>
                {/* Main Card */}
                <Flex gap="5" p="5"
                    style={{alignItems: 'flex-start', alignSelf: 'stretch', borderRadius: 'var(--radius-4)',
                    height: '100%', 
                    background: `linear-gradient(111deg, ${backgroundColor1} 22.52%, ${backgroundColor2} 77.48%)`,
                    }}>
                    {/* Left Section */}
                    <Flex direction="column" justify="between"
                        style={{alignItems: 'flex-start', flex: '1 0 0', alignSelf: 'stretch'}}>
                        <Flex direction="column" gap="5" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                            {/* Content */}
                            <Flex direction="column" align="start" gap="2" style={{alignSelf: 'stretch'}}>
                                <Text size="5" weight="bold" style={{color: `${headingColor}`}}>{heading}</Text>
                                <Text size="2" weight="regular" style={{color: `${contentColor}`}}>{content}</Text>
                            </Flex>
                        </Flex>
                        {/* Source */}
                        <Flex style={{alignSelf: 'stretch', justifyContent: 'flex-end'}}>
                            <Text size="2" weight="regular" style={{color: `${contentColor}`}}>
                                <Link href={source_url} target='_blank' rel='noopener noreferrer'>
                                    <Badge size="1" variant='soft' color='violet'>{source}</Badge>
                                </Link>
                            </Text>
                        </Flex>
                    </Flex>
                    {/* Right Section */}
                    <Flex direction="column" justify="center" style={{alignSelf: 'stretch', alignItems: 'flex-start',
                        flex: '1 0 0', width: '100%', height: '100%',
                        borderRadius: 'var(--radius-2)',
                        backgroundImage: `url(${image_url})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        mixBlendMode: 'luminosity',
                    }}>
                    </Flex>
                </Flex>
            </div>
        );
    }
}
