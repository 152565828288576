import React, { useEffect, useState, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import './FootfallChartColours.css';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const getCSSVariable = (variable) =>
  getComputedStyle(document.documentElement).getPropertyValue(variable).trim();

const darkBarColor = getCSSVariable('--orange-6');
const lightBarColor = getCSSVariable('--orange-9');
const labelColor = getCSSVariable('--orange-11');

export const FootfallChart = ({
  chartHeight,
  labels,
  sample,
  tag,
}) => {

  // Things we need for this card 
  // - openingTime (string)
  // - closingTime (string)
  // - data (array of numbers)
  // - time period of interest (needs to be extracted from the llm)

  const [data, setData] = useState(null);
  const chartRef = useRef(null);

  useEffect(() => {
    const img = new Image();
    img.src = require("../../assets/images/hatch_pattern.png"); // Load image properly with require

    img.onload = () => {
      if (chartRef.current) {
        const ctx = chartRef.current.getContext('2d');
        const fillPattern = ctx.createPattern(img, 'repeat');

        let barChartBackgroundColor;
        if (tag === "none") {
          barChartBackgroundColor = new Array(sample.length).fill(darkBarColor);
        } else if (tag === "all") {
          barChartBackgroundColor = new Array(sample.length).fill(lightBarColor);
        } else if (tag === null) {
          barChartBackgroundColor = new Array(sample.length).fill(lightBarColor);
        } else {

          // Convert tag to number for index
          const tagIndex = parseInt(tag);
          
          // Initialize array
          barChartBackgroundColor = new Array(sample.length);

          if (tagIndex === 0) {
            // Pattern at start, rest dark
            barChartBackgroundColor[0] = fillPattern;
            barChartBackgroundColor.fill(darkBarColor, 1);
          } else if (tagIndex === sample.length - 1) {
            // All light, pattern at end
            barChartBackgroundColor.fill(lightBarColor, 0, sample.length - 1);
            barChartBackgroundColor[sample.length - 1] = fillPattern;
          } else {
            // Light from start to tag
            barChartBackgroundColor.fill(lightBarColor, 0, tagIndex);
            // Pattern at tag
            barChartBackgroundColor[tagIndex] = fillPattern;
            // Dark after tag
            barChartBackgroundColor.fill(darkBarColor, tagIndex + 1);
          }
        }

        setData({
          labels: labels,
          datasets: [
            {
              label: 'Data Set',
              data: sample,
              backgroundColor: barChartBackgroundColor,
              borderWidth: 1,
              borderRadius: 4
            },
          ],
        });
      }
    };
  }, [tag, sample, labels]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          color: labelColor, // Label color
        },
      },
      y: {
        beginAtZero: true,
        display: false, // Hide Y-axis
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        enabled: false, // Hide the tooltip
      }
    },
  };

  return (
    <div style={{ width: "100%" }}>
      <canvas ref={chartRef} style={{ display: data ? 'none' : 'block' }} />
      {data && (
        <Bar
          data={data}
          options={options}
          width="100%"
          height={chartHeight}
        />
      )}
    </div>
  );
};


