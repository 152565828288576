import instance from "./BaseInsightsApi";

export function attemptGetFootfallTraffic(day, locationID) {
  return instance.request({
    url: `location/daily-venue-insights/${day}/${locationID}/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptGetIndustryNewsInsights(day, locationID, numberOfArticles) {
  return instance.request({
    url: `location/industry-news-insights/${day}/${locationID}/${numberOfArticles}/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}
