import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Text, Badge } from '@radix-ui/themes';

export function ExternalInsightsCard({ title, subtitle, time, link, variant = "concise" }) {
  const navigate = useNavigate();

  if (variant === "concise") {
    return (
      <div 
        className="external-impact-card" 
        onClick={() => navigate("/insights/external-insights")}
        style={{
          display: "flex",
          width: "488px", 
          padding: "var(--space-4)", 
          flexDirection: "column", 
          alignItems: "flex-start", 
          gap: "var(--space-4)", 
          alignSelf: "stretch",
          borderRadius: "var(--space-2)",
          background: "linear-gradient(111deg, var(--purple-4) 22.52%, var(--purple-3) 77.48%)",
          cursor: "pointer"
        }}>
        <Flex className="card-subtitle" style={{alignSelf: "stretch"}} display="flex" justify="between" align="center">
          <Text size="2" weight="medium" color="purple">External impact</Text>
          <Text size="1" weight="regular" color="purple">{time}</Text>
        </Flex>
        <Flex className="card-header" style={{alignSelf: "stretch"}} display="flex" direction="column" align="start" gap="2">
          <Text style={{color: "var(--purple-12)"}} size="5" weight="bold">{title}</Text>
          <Text size="2" weight="regular" color="purple">{subtitle}</Text>
        </Flex>
        <Flex style={{alignSelf: "stretch"}} display="flex" height="108px" align="start" gap="4">
          <div style={{height: "108px", width: "220px"}}/>
          <div style={{height: "108px", width: "220px"}}/>
        </Flex>
        <Flex style={{alignSelf: "stretch"}} display="flex" direction="column" justify="center" align="end" gap="2" >
          <a href={link} target="_blank" rel="noopener noreferrer">
            <Badge size="1" variant="soft" color="purple">
              Source?
            </Badge>
          </a>
        </Flex>
      </div>
    );
  } else if (variant === "expanded") {
    return (
      <div 
      className="external-impact-card" 
      onClick={() => navigate("/insights/external-insights")}
      style={{
        display: "flex",
        maxWidth: "1000px", 
        padding: "var(--space-5)", 
        alignItems: "flex-start", 
        gap: "var(--space-5)", 
        alignSelf: "stretch",
        borderRadius: "var(--space-2)",
        background: "linear-gradient(111deg, var(--purple-4) 22.52%, var(--purple-3) 77.48%)"
      }}>
        <Flex className="card-left" style={{alignSelf: "stretch"}} display="flex" direction="column" justify="between" align="start" flexGrow="1" >
          <Flex className="card-header" style={{alignSelf: "stretch"}} display="flex" direction="column" align="start" gap="5">
            <Text color="purple" size="1" weight="regular">{time}</Text>
            <Flex className="title-group" style={{alignSelf: "stretch"}} display="flex" direction="column" align="start" gap="2">
              <Text size="5" weight="bold">{title}</Text>
              <Text size="2" weight="regular">{subtitle}</Text>
            </Flex>
          </Flex>
          <Flex className="footer" style={{alignSelf: "stretch"}} display="flex" direction="column" justify="center" align="end" gap="2">
            <a href={link} target="_blank" rel="noopener noreferrer">
              <Badge size="1" variant="soft" color="purple">
                Source?
              </Badge>
            </a>
          </Flex>
        </Flex>
        <Flex className="card-right" style={{alignSelf: "stretch"}} display="flex" direction="column" justify="center" align="start" gap="5" flexGrow="1">
          <Flex style={{alignSelf: "stretch"}} display="flex" align="start" gap="5" flexGrow="1">
            <div style={{height: "164px", width: "220px"}}/>
            <div style={{height: "164px", width: "220px"}}/>
          </Flex>
          <div style={{height: "164px", width: "464px"}}/>
        </Flex>
      </div>
    )
  }
}