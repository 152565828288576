import * as React from 'react';
import { Flex, Text, Button } from '@radix-ui/themes';
import { FootfallCard, LocationSelect } from 'components';
import { ReactComponent as GradientSparklesIcon } from 'assets/icons/gradient_sparkles_orange.svg';
import { useLocationContext, useFootfallCardContext, useTimeContext } from "./InsightsLayout";

export default function AllFootfallInsights() {

  const { initialItems, sharedLocation, setSharedLocation } = useLocationContext();
  const { footfallCards } = useFootfallCardContext();
  const { filteredFootfallCards, tagArray } = useTimeContext();

  return (
    <Flex className="content-container" display="flex" direction="column" align="start" flexGrow="1">
      <Flex className="frame-528172569" style={{alignSelf: "stretch"}} display="flex" px="7" py="8" direction="column" align="center" gap="7" flexGrow="1">
        <Flex className="frame-528173200" display="flex" py="5" direction="column" justify="center" align="center" gap="5">
          <Flex className="header-title" style={{alignSelf: "stretch"}} display="flex" align="center" gap="3" >
            <GradientSparklesIcon />
            <Text style={{color: "var(--orange-12)"}} size="8" weight="bold">Footfall Forecast</Text>
          </Flex>
        </Flex>
        <Flex className="header-container" display="flex" align="center" gap="5" >
          <Flex className="header-buttons" display="flex" align="start" gap="2">
            <Button size="3" variant="soft" color="accent" style={{cursor: 'pointer'}}>Today</Button>
            <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>Tomorrow</Button>
            <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>W</Button>
            <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>M</Button>
            <Button size="3" variant="soft" color="gray" style={{cursor: 'pointer'}}>Y</Button>
          </Flex>
          <LocationSelect
            initialItems={initialItems}
            sharedLocation={sharedLocation}
            setSharedLocation={setSharedLocation}
          />
        </Flex>
        <Flex className="card-container" style={{alignSelf: "center"}} display="flex" maxWidth="1000px" direction="column" gap="5">
          <Flex className="two-column-alignment" style={{alignSelf: "stretch"}} display="flex" maxWidth="1000px" align="start" gap="5">
            <Flex className="internal-column-alignment" style={{alignSelf: "stretch"}} display="flex" maxWidth="1000px" direction="column" align="start" gap="5" flexGrow="1">
              {filteredFootfallCards
                .filter((_, index) => index % 2 === 0)
                .map((card, index) => (
                  <FootfallCard 
                    key={index}
                    cardWidth='490px'
                    chartHeight='108px'
                    text={footfallCards?.insights[card]}
                    data={footfallCards?.chart_data}
                    tag={tagArray[2 * index]}
                  />
                ))
              }

              {/* add cards belonging in the first column here! */}
            </Flex>
            <Flex className="internal-column-alignment" style={{alignSelf: "stretch"}} display="flex" maxWidth="1000px" direction="column" align="start" gap="5" flexGrow="1">
              {filteredFootfallCards
                .filter((_, index) => index % 2 === 1)
                .map((card, index) => (
                  <FootfallCard 
                    key={index}
                    cardWidth='490px'
                    chartHeight='108px'
                    text={footfallCards?.insights[card]}
                    data={footfallCards?.chart_data}
                    tag={tagArray[2 * index + 1]}
                  />
                ))
              }

              {/* add cards belonging in the second column here! */}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}