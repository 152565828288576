import * as React from 'react';
import { Flex, Text } from '@radix-ui/themes';

export function PotentialActionsCard({title, subtitle, backgroundColor, titleColor, subtitleColor}) {

  return (
    <div
      className="potential-actions-card"
      style={{
        display: "flex", 
        height: "264px",
        padding: "var(--space-4)", 
        flexDirection: "column", 
        justifyContent: "space-between",
        alignItems: "flex-start",  
        background: `${backgroundColor}`,
        borderRadius: "var(--radius-4)",
        flex: "1 0 0"
      }}>
        <Flex className="card-header" style={{alignSelf: "stretch"}} display="flex" direction="column" align="start" gap="2" >
          <Text style={{color: `${titleColor}`}} size="3" weight="medium">{title}</Text>
          <Text style={{color: `${subtitleColor}`}} size="2" weight="light">{subtitle}</Text>
        </Flex>
        <div style={{height: "120px", width: "285px"}}/>
    </div>
  );
}