import RouteManager from "RouteManager";
import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemeProvider, useTheme } from "./context/ThemeContext";  // Import the ThemeContext
import { ShadThemeProvider } from "context/ThemeProvider";
import { ToastProvider } from "./context/ToastContext";
import "react-toastify/dist/ReactToastify.css";
import '@radix-ui/themes/styles.css';
import { Theme } from "@radix-ui/themes";
import './index.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: 1000,
    },
  },
});

function ThemedApp() {
  const { appearance } = useTheme();  // Access the theme context

  return (
    <Theme
      appearance={appearance}  // Use theme from context
      accentColor="blue"
      grayColor="gray"
      panelBackground="solid"
      scaling="100%"
      style={{
        height: '100vh',
        width: '100vw',
      }}
    >
      <RouteManager />
      <ReactQueryDevtools initialIsOpen={false} />
    </Theme>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <ShadThemeProvider
          attribute="class"
          defaultTheme="system"
          enableSystem
          disableTransitionOnChange
        >
          <ToastProvider>
            <ThemedApp />  {/* Render the app within the ThemeProvider */}
          </ToastProvider>
        </ShadThemeProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
